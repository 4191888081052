import { Form, Formik, FormikHelpers } from 'formik';

import { Spinner } from 'components/shared/Spinner';
import { TextField } from 'components/shared/forms/TextField';

import { useForm } from './AuthForm.hooks';

interface IProps {
  onSubmit(credentials: { email: string; password: string }): Promise<void>;
  submitLabel: string;
  loading: boolean;
}

export type TSubmit = (
  values: {
    email: string;
    password: string;
  },
  formikHelpers: FormikHelpers<{
    email: string;
    password: string;
  }>,
) => Promise<any>;

export const AuthForm = ({
  onSubmit,
  submitLabel,
  loading,
}: IProps): JSX.Element => {
  const { error, submitHandler } = useForm({ onSubmit });

  return (
    <div className="">
      {!!error && (
        <p className="mb-4 rounded-lg border-2 border-red-200 bg-red-100 py-2 text-center text-red-500">
          {error}
        </p>
      )}

      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={submitHandler}
      >
        <Form>
          <div className="flex flex-col">
            <div className="mb-4">
              <TextField
                id="email"
                name="email"
                placeholder="Work email"
                className="w-full text-[12px]"
                updateOnChange
              />
            </div>

            <div className="mb-6">
              <TextField
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                className="w-full text-[12px]"
                updateOnChange
              />
            </div>

            <div className="w-full">
              <button
                className="flex w-full flex-row justify-center rounded-[10px] bg-carnationRed py-4 text-white"
                type="submit"
              >
                {submitLabel}

                {loading && (
                  <span className="ml-3">
                    <Spinner className="text-white" size={24} />
                  </span>
                )}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
