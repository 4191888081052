import { AuthErrorCodes } from 'firebase/auth';

export const authError = (errorCode: string) => {
  switch (errorCode) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'invalid password';
    case AuthErrorCodes.INVALID_EMAIL:
      return 'invalid email';
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
      return 'credentials already in use';
    default:
      return errorCode;
  }
};
