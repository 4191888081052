export const FooterMenu = () => {
  return (
    <div className="mt-[6px] text-[8px] whitespace-nowrap">
      <a
        href="https://acehub.io/legal-notice"
        target={'_blank'}
        rel="noreferrer"
        className="opacity-60 hover:opacity-100 transition-opacity"
      >
        Legal Notice
      </a>

      <span className="px-2 opacity-60">|</span>

      <a
        href="https://acehub.io/privacy-notice"
        target={'_blank'}
        rel="noreferrer"
        className="opacity-60 hover:opacity-100 transition-opacity"
      >
        Privacy Notice
      </a>

      <span className="px-2 opacity-60">|</span>

      <a
        href="https://acehub.io/terms-conditions"
        target={'_blank'}
        rel="noreferrer"
        className="opacity-60 hover:opacity-100 transition-opacity"
      >
        Terms &amp; Conditions
      </a>
    </div>
  );
};
