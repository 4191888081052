import { Variants, useReducedMotion } from 'framer-motion';

export const useFadeInTransition = (): {
  container: Variants;
  item: Variants;
} => {
  const shouldReduceMotion = useReducedMotion();

  const container: Variants = {
    hidden: { opacity: shouldReduceMotion ? 1 : 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
      },
    },
  };

  const item: Variants = {
    hidden: {
      position: 'absolute',
      opacity: shouldReduceMotion ? 1 : 0,
    },
    show: {
      position: 'relative',
      opacity: 1,
    },
  };

  return {
    container,
    item,
  };
};
